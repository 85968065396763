import React, { Component } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom"
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable"
import "./App.scss"
import { observer, inject } from "mobx-react"
import { Config, Client } from 'tozny-browser-sodium-sdk'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
})

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
})

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
})

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
})

const PrivateRoute = ({ component: Component, ...rest }) => {
  let clientCredentials = sessionStorage.getItem("client_credentials")
  let auth = clientCredentials !== null
  return (
    <Route
      {...rest}
      render={props => {
        return auth === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }}
    />
  )
}

class App extends Component {
  componentWillMount() {
    const creds = window.sessionStorage.getItem("client_credentials")
    if (creds) {
      let parsedJSON
      try {
        parsedJSON = JSON.parse(creds)
      } catch (error){
        console.error("bad credential, yo.")
      }
      let config = Config.fromObject(creds)
      let client = new Client(config)
      client.query().next().then(r => {
        this.props.store.activeClientCreds = parsedJSON
        this.props.store.platformClient = client
        this.props.store.activeClientRecords = r
      })
    }
  }
  render() {
    console.log('APP RE_REDNERED')

    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default inject("store")(observer(App))
