import { observable, decorate, computed } from 'mobx'

import isEmpty from './utils/isEmpty'

class Store {
    // Tozny Platform client used for this session.
    platformClient = null
    activeRecordId = null
    activeClientCreds = null
    activeClientRecords = null

    get activeClient () {
      return !isEmpty(this.clients) ? this.clients.filter(c => c.client_id === this.activeClientId).pop() : null
    }

    get activeRecord () {
      return !isEmpty(this.activeClientRecords) ? this.activeClientRecords.filter(c => c.meta.recordId === this.activeRecordId).pop() : null
    }

}

// window.store present for dev
var store = window.store = new Store()

export default store

decorate(store, {
  platformClient: observable,
  activeRecordId: observable,
  activeClientCreds: observable,
  activeClientRecords: observable
})
